<template>
    <div
        class="help-wrapper"
        @click="showHelp"
    >
        <i class="fas fa-question-circle" />
    </div>
</template>

<script>
export default {
    props: {
        wikiId: {
            type: String,
            required: true
        },
    },

    methods: {
        showHelp(){
            this.$router.push({ query: { wiki: this.wikiId } })
        }
    }
};
</script>

<style>
.help-wrapper {
    position: relative;
    display: inline;
    cursor: pointer;
}

</style>