<template>
    <loading-layer :is-loading="buffer.isLoading" />

    <form
        :loading="buffer.isLoading"
        @submit="checkForm"
    >
        <div class="form-header">
            <p class="h6 font-weight-bold">
                {{ __('MENUBAR.ENVIRONMENT') }}
            </p>
            <input
                v-if="buffer.loadingErrorMessage == ''"
                class="btn-wide btn-primary ml-auto"
                type="submit"
                :value="__('BUTTONS.UPDATE')"
            >
        </div>
        <p
            v-if="buffer.formError.hasErrors"
            class="text-danger text-right"
        >
            There are errors in the form !!
        </p>

        <div
            v-if="buffer.errorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.errorMessage }}
        </div>

        <div
            v-if="buffer.successMessage != ''"
            class="alert alert-success"
            role="alert"
        >
            {{ buffer.successMessage }}
        </div>

        <year-selector
            v-model="buffer.selectedYear"
            @year-updated="updateYear()"
        />

        <div
            v-if="buffer.loadingErrorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.loadingErrorMessage }}
        </div>

        <div v-if="buffer.loadingErrorMessage == '' && !buffer.isLoading">
            <div
                v-for="facility in buffer.facilities"
                :key="facility.name"
            >
                <p class="h4">
                    {{ facility.name }}
                </p>

                <div
                    v-for="specieId in facility.species"
                    :key="specieId"
                    class="ml-3"
                >
                    <p class="h6">
                        · {{ getSpecieName(specieId) }}
                    </p>
                    <!-- KPIS -->

                    <div class="ml-3 d-flex flex-wrap">
                        <kpi
                            v-for="kpi in buffer.environmentKPIs"
                            :key="kpi.kpiId"
                            v-model="formData[facility.name][specieId][kpi.kpiId].value"
                            class="p-1"
                            :name="kpi.name"
                            :unit="kpi.unit"
                            :error="formData[facility.name][specieId][kpi.kpiId].error"
                            :help-id="buffer.kpisWithHelp.includes(kpi.kpiId) ? `kpi-${kpi.kpiId}` : ''"
                        />
                    </div>
                </div>
                <br>
            </div>
        </div>
    </form>
</template>

<script>
import YearSelector from '../../components/year_selector.vue';
import LoadingLayer from '../../components/loading_layer.vue';

import Constants from '../../Constants';

import { getActivityName, getSpecieName, getKpisFromType } from '../../db/db-utils.js';
import { isInt } from '../../utils/form-utils.js';

import API from '../../utils/api-utils.js';
import __ from '../../utils/translator.js';

import kpi from '../../components/kpi.vue';

const environmentKPIs = getKpisFromType(Constants.KPI_ENVIRONMENT_ID);

export default {

    components: {
        kpi,
        YearSelector,
        LoadingLayer
    },
    inject: ['GLOBAL_ACTIVITIES'],

    data() {
        return {
            buffer: {
                environmentKPIs,
                kpisConfig: {}, //kpi index contain config of thay kpi: {<kpiId>: {config} }
                facilities: [],

                kpisWithHelp: [8, 9, 10, 12, 13],
                yearRange: Array.from(
                    Array(
                        Constants.MAX_KPIDATE_VALUE - (Constants.MIN_KPIDATE_VALUE-1)).keys()
                    ).map(e => e + Constants.MIN_KPIDATE_VALUE),

                selectedYear: Constants.MAX_KPIDATE_VALUE,

                isLoading: true,
                errorMessage: '',
                successMessage: '',
                loadingErrorMessage: '',

                formError: {
                    hasErrors: false
                }
            },

            /**
             * Hierarchy:
             * Form data -> facility -> specie -> kpi -> { value, error }
             */
            formData: {}
        }
    },

    //First of all, fetch API for facilities, and if exists fetch data.
    created() {
        const promises = [];
        promises.push(this.fetchFacilities());
        promises.push(this.setKPIsConfiguration());
        Promise.all(promises)
            .then(() => this.cleanupFacilities())
            .finally(() => this.updateYear());
    },

    methods: {
        updateYear() {
            this.resetMessages();

            this.buffer.isLoading = true;
            const year = this.buffer.selectedYear;

            API.read.kpi.environment(year)
                .then(response => {
                    if (response.ok) {
                        this.setDataIntoForm(response.data);
                    } else {
                        this.buffer.loadingErrorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        },

        setDataIntoForm(apiData) {
            this.resetFormStructure();

            apiData.forEach(kpi => {
                /**
                 * Check existance of indexes in the form,
                 * facility / specie may be removed
                 */
                if (kpi.facility in this.formData &&
                    kpi.specieId in this.formData[kpi.facility] &&
                    kpi.kpiId in this.formData[kpi.facility][kpi.specieId])
                        this.formData[kpi.facility][kpi.specieId][kpi.kpiId].value = kpi.value;
            });
        },

        resetMessages() {
            this.buffer.errorMessage = '';
            this.buffer.successMessage = '';
            this.buffer.loadingErrorMessage = '';
            this.buffer.formError.hasErrors = false;
        },

        checkForm(ev) {
            ev.preventDefault();
            
            this.resetMessages();
            
            let ok = true;

            const data = [];
            Object.entries(this.formData).forEach(([facility, species]) => {
                Object.entries(species).forEach(([specieId, kpis]) => {
                    Object.entries(kpis).forEach(([kpiId, kpiObj]) => {
                        // first of all, reset errors
                        kpiObj.error = '';

                        const value = kpiObj.value;
                        const currentConfig = this.buffer.kpisConfig[kpiId];

                        //Validate form
                        if (value === '') {
                            kpiObj.error = 'Empty';
                            ok = false;
                            return;
                        }

                        if (value > currentConfig.max || value < currentConfig.min) {
                            kpiObj.error = `Value must be between ${currentConfig.min} and ${currentConfig.max}`;
                            ok = false;
                            return;
                        }

                        //type check
                        if (currentConfig.type == 'int' && !isInt(value)) {
                            kpiObj.error = `Value must be integer`;
                            ok = false;
                            return;   
                        }

                        if (currentConfig.type == 'float' && Number(value) !== value) {
                            kpiObj.error = `Value must be numeric`;
                            ok = false;
                            return;   
                        }

                        //Store form data ready to send
                        data.push({
                            facility,
                            kpiId: Number(kpiId),
                            value,
                            specieId: Number(specieId),
                            activityId: null,
                            year: this.buffer.selectedYear,
                            typeId: Constants.KPI_ENVIRONMENT_ID
                        });
                    });
                });
            });

            if (!ok) {
                this.buffer.formError.hasErrors = true;
                return;
            }

            this.buffer.isLoading = true;
            API.update.kpi(data)
                .then(response => {
                    if (response.ok) {
                        this.buffer.successMessage = 'Kpis successfully updated';
                    } else {
                        this.buffer.errorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        },

        /**
         * Builds data structure for storaging form data
         * 
         * An object hirarchy is created for storaging indexed information for each kpi.
         * Data is accessed in this way:
         * formData[<facilityName>][<specieId>][<kpiId>].value
         */
        resetFormStructure() {
            this.buffer.facilities.forEach(fac => {
                this.formData[fac.name] = {};
                fac.species.forEach(specieId => {
                    this.formData[fac.name][specieId] = {};
                    environmentKPIs.forEach(kpi => {
                        this.formData[fac.name][specieId][kpi.kpiId] = {
                            value: '',
                            error: ''
                        };
                    })
                });
            });
        },

        /**
         * Adds a property to each facility called "species"
         * which is an array with the species that the facility works with
         */
        cleanupFacilities() {
            this.buffer.facilities.forEach(fac => {
                if (fac.species == undefined)
                    fac.species = [];

                fac.activities.forEach(obj => {
                    const id = obj.specieId;
                    if (fac.species.indexOf(id) == -1)
                        fac.species.push(id);
                });
            });
        },

        async fetchFacilities() {
            await API.read.facility()
                .then(response => {
                    if (response.ok) {
                        this.buffer.facilities = response.data;
                    } else {
                        this.buffer.loadingErrorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.');
        },

        /**
         * Configuration of each kpi in form of:
         * <kpiId>: {
         *     max: 0,
         *     min: 0,
         *     ...
         * }
         */
        async setKPIsConfiguration() {
            environmentKPIs.forEach(kpi => {
                this.buffer.kpisConfig[kpi.kpiId] = kpi.config;
            });
        },

        getActivityName,
        getSpecieName,
        __ //translator
    }
}
</script>