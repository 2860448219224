<template>
    <div class="form-group">
        <label>{{ name }}({{ unit }})
            <help
                v-if="helpId != ''"
                :wiki-id="helpId"
            />
            <input
                v-model.number="localValue"
                type="number"
                :class="`${error != '' ? 'is-invalid' : ''} form-control mt-2`"
                :placeholder="name"
                step="any"
            >
            <div
                v-if="error != ''"
                class="invalid-feedback"
            >
                {{ error }}
            </div>
        </label>
    </div>
</template>

<script>
import Help from '../components/help.vue';

export default {
    components: {
        Help
    },

    props: {
        name: {
            type: String,
            required: true
        },
        unit: {
            type: String,
            required: true
        },
        modelValue: {
            type: [Number, String],
            required: true
        },
        error: {
            type: String,
            required: false,
            default: () => ''
        },
        helpId: {
            type: String,
            required: false,
            default: () => ''
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            localValue: this.modelValue
        }
    },

    watch: {
        localValue(newVal) {
            this.$emit('update:modelValue', newVal);
        },

        modelValue(newVal) {
            this.localValue = newVal;
        }
    }
}
</script>